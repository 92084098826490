import React from "react"

import Layout from "../components/Layout"
import './css/404.css'
import Button from "@material-ui/core/Button";
import {Link} from "gatsby";
import SEO from "../components/SEO";

const NotFoundPage = () => (
    <Layout id='NotFound'>
        <SEO title="404: Not found" />
        <div style={{marginTop: '100px', padding: '2em', textAlign: 'center', height: '60vh'}}>
            <h1>404 NOT FOUND</h1>
            <p>Die angeforderte Seite existiert leider nicht...</p>
            <Link to='/'>
                <Button variant="contained"  size="small" color="primary">
                    Zur Startseite
                </Button>
            </Link>
        </div>
    </Layout>
);

export default NotFoundPage
